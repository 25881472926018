import apiClient from '../../axiosConfig/apiClient'
import randomApi from '../../axiosConfig/randomApi'
import {
  EXPORT_SALES,
  MULTI_PACKAGE_LABEL,
  PACKING_URL,
  PICKING_URL
} from '../../constants/urls'
import { downloadFileUrl } from '../../utils/files'

export const packingList = async (config) => {
  const { params: packageIds } = config
  const response = await randomApi().post(PACKING_URL, { packageIds })

  return response.data
}

export const pickingList = async (config) => {
  const { params: packages } = config
  const response = await randomApi().post(PICKING_URL, { packages })

  return response.data
}

export const exportSalesRequest = async (body) => {
  const response = await randomApi().post(EXPORT_SALES, body)

  downloadFileUrl(response.data.xlsx || response.data.url)
}

export const exportDistributionPackages = async (body) => {
  const response = await apiClient.post('/companies/replenishment/xlsx', body)

  if (response.data.fileUrl) {
    downloadFileUrl(response.data.fileUrl)
    return 3000
  }
  return response.data.salesCount
}

export const multiPackageLabel = async (packageId, packages, splittedProducts) => {
  const distribution = packages.map((packageProducts) =>
    packageProducts.map((product) => {
      const item = { sku: product.sku, quantity: product.quantity }
      if (splittedProducts[product.sku]) item.name = product.productName
      return item
    })
  )

  const body = {
    packageId,
    distribution
  }

  if (Object.keys(splittedProducts).length > 0) body.splittedProducts = splittedProducts

  const response = await randomApi().post(MULTI_PACKAGE_LABEL, body)

  return response.data
}

export const getSales = async (params) => {
  const response = await randomApi().get('/sales', params)

  return response.data
}

export const getSale = async (saleId, params={}) => {
  const response = await randomApi().get(`/sales/${saleId}`, params)

  return response.data
}

export const fetchSale = async (config) => {
  const { params } = config
  const { packageId } = params
  delete params.packageId

  const data = await getSale(packageId, { ...config, params })


  return data
}

export const masiveOrderCreation = async (body) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const response = await randomApi().post('/company/package/bulk/xlsx', body, config)

  return response.data
}

export const getCourierLogs = async (pinflagId) => {
  const response = await randomApi().get(`/packages/${pinflagId}/courier/logs`)

  return response.data
}

export const putPackageReception = async (packageIds) => {
  const body = {
    packages: packageIds.map((packageId) => ({
      packageId,
      storePickup: true
    }))
  }

  await randomApi().put('/companies/couriers/package/reception', body)
}

export const getSalesWithError = async (params) => {
  const response = await randomApi().get('/packages/packages-with-error', params)

  return response.data.packages_with_error
}

export const duplicateOrder = async (packageId, duplicationBody) => {
  const response = await apiClient.post(`/packages/${packageId}/duplicate`, duplicationBody)

  return response.data
}

