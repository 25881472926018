import { useState } from 'react'

import { updatePixelCredentials } from '../../helpers/request/stats'
import { changePurchaseEventValue, setPixelCredentials, setPixelHasCredentialsSave, setPixelIsActive } from '../../helpers/pixelCredentials'

const useSubmitPixelCredentials = () => {

  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)

  const onSubmit = async (body, isActiveValue, setPixels) => {
    setLoadingSubmit(true)
    let errorSubmitValue = false
    try {
      await updatePixelCredentials(body)
      await changePurchaseEventValue(body.company_id, isActiveValue)
    } catch {
      errorSubmitValue = true
    } finally {
      setErrorSubmit(errorSubmitValue)
      setOpenResponseModal(true)
      setLoadingSubmit(false)
      if (!errorSubmitValue) {
        setPixelCredentials(body.pixel_name, body.new_credentials, setPixels)
        setPixelHasCredentialsSave(body.pixel_name, true, setPixels)
        setPixelIsActive(body.pixel_name, isActiveValue, setPixels)
      }
    }
  }

  return { onSubmit, openResponseModal, setOpenResponseModal, loadingSubmit, errorSubmit }
}

export default useSubmitPixelCredentials
