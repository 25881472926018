import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Table, TableRow } from '../../shared/Table/Table'
import { isSubstring } from '../../../utils/strings'
import { sortArrayByKey } from '../../../utils/arrays'
import SearchBar from '../../shared/SearchBar'
import ROUTES from '../../../constants/routes'
import { identifierCreator, saleStatusColor } from '../../../helpers/sales'
import { PACKAGE_STATUS_TRANSLATE } from '../../../constants/packageStatus'
import { getFormattedDate } from '../../../utils/dates'
import { searchTermsGenerator } from '../../../utils/filters'

const PackagesTable = ({ packages }) => {
  const [searchBarInput, setSearchBarInput] = useState('')
  const [productsList, setProductsList] = useState(packages)
  const [searchResult, setSearchResult] = useState(packages)

  const navigate = useNavigate()

  const searchBarFilter = (searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const searchedProducts = productsList.filter(
        (product) => searchTerms.some((term) => (
          isSubstring(product.CODBULTOCLIENTE, term) ||
          isSubstring(product.Shipping?.trackingNumber, term)
        ))
      )
      setSearchResult(searchedProducts)
    } else {
      setSearchResult(packages)
    }
  }

  const handleSort = (sortValues, order) => {
    const sortedProducts = sortArrayByKey(productsList, sortValues, order)
    setProductsList(sortedProducts)

    const sortedSearchResult = sortArrayByKey(searchResult, sortValues, order)
    setSearchResult(sortedSearchResult)
  }

  const sortValues = {
    'O. venta': { key: 'CODBULTOCLIENTE', type: 'string' },
    'O. Transporte': { key: 'trackingNumber', type: 'string' },
    Estado: { key: 'status', type: 'string' },
    Fecha: { key: 'FECHAREALRECEPCION', type: 'date' }
  }

  return (
    <>
      <div className="mx-10 bg-white border-x border-t px-4 py-2 rounded-t">
        <div className="w-52">
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchBarFilter}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        </div>
      </div>
      <Table
        sortValues={sortValues}
        sortData={handleSort}
        headers={['O. venta', 'O. Transporte', 'Estado', 'Fecha']}
        maxHeight="max-h-[60vh]"
      >
        {searchResult.map((packageItem) => (
          <TableRow
            key={packageItem.CODBULTOCLIENTE}
            onClick={() => navigate(`${ROUTES.SALES}/${packageItem.IDBULTO}`)}
            items={[
              `${identifierCreator(packageItem.Empresa.companyName)}-${
                packageItem.CODBULTOCLIENTE}`,
              packageItem.trackingNumber,
              <div className={`w-fit px-2 py-1 rounded ${saleStatusColor(packageItem.status)}`}>
                {PACKAGE_STATUS_TRANSLATE[packageItem.status]}
              </div>,
              getFormattedDate(packageItem.FECHAREALRECEPCION)
            ]}
          />
        ))}
      </Table>
    </>
  )
}

export default PackagesTable
