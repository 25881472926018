import React, { createContext, useState, useMemo } from 'react'
import secureLocalStorage from 'react-secure-storage'

export const PixelCredentialsContext = createContext()

const PixelCredentialsProvider = ({ children }) => {
  const [companyId, setCompanyId] = useState(secureLocalStorage.getItem('companiesIds'))
  const [areCredentialsLoaded, setAreCredentialsLoaded] = useState(null)
  const [pixels, setPixels] = useState({
    ga4: {
      credentials: { pixelId: '', pixelAccessToken: '' },
      hasCredentialsSave: false,
      isActive: false
    },
    facebook: {
      credentials: { pixelId: '', pixelAccessToken: '' },
      hasCredentialsSave: false,
      isActive: false
    }
  })

  const value = useMemo(
    () => ({
      companyId,
      setCompanyId,
      pixels,
      setPixels,
      areCredentialsLoaded,
      setAreCredentialsLoaded
    }),
    [areCredentialsLoaded, companyId, pixels]
  )

  return <PixelCredentialsContext.Provider value={value}>
    {children}
  </PixelCredentialsContext.Provider>
}

export default PixelCredentialsProvider
