import { React, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SIDEBAR_BUTTONS } from '../../constants/general'
import { TICKETS_STATUS, TICKET_STATES_INDEX } from '../../constants/tickets'
import { TICKETS_URL } from '../../constants/urls'
import { isSubstring } from '../../utils/strings'
import SectionHeader from '../../components/shared/SectionHeader'
import Pagination from '../../components/shared/Pagination/Pagination'
import SearchBar from '../../components/shared/SearchBar'
import ROUTES from '../../constants/routes'
import useFetch from '../../hooks/useFetch'
import TicketsTable from '../../components/Tickets/TicketsTable'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import Button from '../../components/shared/Button'
import { sortArrayByKey } from '../../utils/arrays'
import { searchTermsGenerator } from '../../utils/filters'
import NoAccess from '../../components/NoAccess'
import { AuthContext } from '../../contexts/Store'
import ExportTickets from '../../components/Tickets/ExportTickets'
import TicketsSelection from '../../components/Tickets/SelectionBar/TicketsSelection'
import { TicketsContext } from '../../contexts/TicketsContext'

const Tickets = () => {
  const [tickets, setTickets] = useState([])
  const [searchBarInput, setSearchBarInput] = useState('')
  const [selectedFilters, setSelectedFilters] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchResult, setSearchResult] = useState([])
  const [selectedTickets, setSelectedTickets] = useState([])
  const { setAllFilteredTickets } = useContext(TicketsContext)

  const navigate = useNavigate()

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const setTicketsData = useCallback((data) => {
    const flattenTickets = data.map((ticket) => {
      const item = { ...ticket, ...ticket.package }
      delete item.package
      return item
    })
    flattenTickets.sort((ticketA, ticketB) => ticketB.id - ticketA.id)

    setTickets(flattenTickets)
    setSearchResult(flattenTickets)
  }, [])

  const { isLoading, error } = useFetch(TICKETS_URL, setTicketsData)

  useEffect(() => {
    setTickets([])
    return () => {
      setTickets([])
    }
  }, [])

  const searchBarFilter = (ticketsList, searchTerm) => {
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const filteredTickets = ticketsList.filter(
        (ticket) => searchTerms.some((term) => (
          isSubstring(ticket.id.toString(), term) ||
          (ticket.pinflagId && isSubstring(ticket.pinflagId, term)) ||
          (ticket.courier && isSubstring(ticket.courier, term)) ||
          (ticket.orderId && isSubstring(ticket.orderId, term)) ||
          (ticket.customerName && isSubstring(ticket.customerName, term))
        ))
      )
      setSearchResult(filteredTickets)
    } else {
      setSearchResult(tickets)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    const ticketsList = tickets
    searchBarFilter(ticketsList, searchTerm)
  }

  const handleSort = (sortValues, order) => {
    const sortedCurrentTickets = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedCurrentTickets)
    const sortedTickets = sortArrayByKey(tickets, sortValues, order)

    setTickets(sortedTickets)
  }

  const stateFilteredTickets =
    selectedFilters.length > 0
      ? searchResult.filter((ticket) =>
          selectedFilters.includes(TICKET_STATES_INDEX[ticket.status])
        )
      : searchResult

  useEffect(() => {
    setAllFilteredTickets((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(stateFilteredTickets)) {
        return stateFilteredTickets
      }
      return prev
    })
  }, [stateFilteredTickets, setAllFilteredTickets])

  const currentTickets = stateFilteredTickets.slice(pageSize * (page - 1), pageSize * page)

  if (!hasAccess('tickets')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  return (
    <div className="relative h-screen flex flex-col bg-light-grey">
      <SectionHeader
        title={SIDEBAR_BUTTONS.tickets.text}
        searchBar={
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        }
      />
      <ActionsBar
        statusFilters={TICKETS_STATUS}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        rightChildren={
          <div className="flex gap-1 my-auto ml-1">
            <ExportTickets />
            <Button
              color="bg-normal-pinflag"
              onClick={() => navigate(ROUTES.NEW_TICKET.split(':')[0])}
              small
            >
              <span className="whitespace-nowrap">Crear Solicitud</span>
            </Button>
          </div>
        }
      />
      <TicketsTable
        tickets={currentTickets}
        selectedTickets={selectedTickets}
        setSelectedTickets={setSelectedTickets}
        handleSort={handleSort}
        isLoading={isLoading || loadingResources}
        error={error}
      />
      <div className="h-16 flex place-content-end mx-10">
        {stateFilteredTickets.length > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={stateFilteredTickets.length}
          />
        )}
      </div>
      {selectedTickets.length > 0 && (
        <div className="absolute bottom-4 left-10">
          <TicketsSelection
            selectedTickets={selectedTickets}
            handleCloseBar={() => {
              setSelectedTickets([])
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Tickets
