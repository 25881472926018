import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import Store from './contexts/Store'
import ErrorBoundary from './components/ErrorBoundary'

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Store>
        <App />
      </Store>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()

reportWebVitals()
