import ROUTES from '../constants/routes'

const getUnreadMessagesByTicket = (notifications) => notifications
  .filter(notification => notification.payload?.title === 'Nuevo mensaje en tu solicitud' &&
    !notification.read)
  .reduce((acc, notification) => {
    const ticketId = notification.payload?.action?.url.replace(`${ROUTES.TICKETS}/`, '')
    if (ticketId) {
      acc[ticketId] = (acc[ticketId] || 0) + 1
    }
    return acc
  }, {}
)

export const countNewMessagesByTicketId = (notifications, prevMessages, currentTicketId,
  forceUpdateAll) => {
  if (forceUpdateAll) {
    return getUnreadMessagesByTicket(notifications)
  }

  const newCounts = getUnreadMessagesByTicket(notifications)

  return Object.keys({ ...prevMessages, ...newCounts }).reduce((acc, ticketId) => {
    acc[ticketId] = ticketId !== currentTicketId
      ? newCounts[ticketId] || prevMessages[ticketId] || 0
      : prevMessages[ticketId] || 0

    return acc
  }, {})
}


export const readNotificationHelper = async (ticketsInfo, ticketId, readNotificationsAndUpdate) => {
  const notificationsFound = ticketsInfo?.notifications?.filter(
    (notification) =>
      notification.payload?.action?.url === `${ROUTES.TICKETS}/${ticketId}` && !notification.read
  )

  if (notificationsFound && notificationsFound.length > 0) {
    const notificationsIds = notificationsFound.map((notification) => notification.id)
    await readNotificationsAndUpdate(notificationsIds, notificationsFound[0].payload.category)
  }
}
