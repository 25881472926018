/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'

import { duplicateOrder } from '../../../../helpers/request/sales'
import RawLoader from '../../../shared/Loader/RawLoader'

const DuplicateOrder = ({ orderId, packageId, companyId }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [newId, setNewId] = useState(`${orderId || ''}-R`)
  const [success, setSuccess] = useState(false)

  const defaultOrderId = `${orderId || ''}-R`

  const handleDuplicateOrder = async () => {
    const finalOrderId = newId.trim() || defaultOrderId

    setLoading(true)
    try {
      await duplicateOrder(packageId, {
        companyId: Number(companyId),
        newOrderId: finalOrderId
      })
      setSuccess(true)

      setTimeout(() => {
        window.location.reload()
      }, 4000)
    } catch (err) {
      if (err.response) setError(err.response.data.message)
      else setError('Ha ocurrido un error')
      setLoading(false)
    }
  }

  if (success) {
    return (
      <div className="m-2 flex flex-col items-center p-4 text-center">
        <div className="mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-green">
          <svg
            className="h-6 w-6 text-green"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <h3 className="mb-4 text-lg font-medium">Orden duplicada con éxito</h3>
        <div className="mb-4">
          <label
            htmlFor="newId"
            className="mb-2 block text-sm font-medium text-gray-700"
          >
            Nueva orden de venta
          </label>
          <input
            id="newId"
            type="text"
            value={newId || defaultOrderId}
            disabled
            className="w-full rounded-md border border-gray-300 bg-gray-50 p-2 text-sm"
          />
        </div>
        <button
          type="button"
          className="w-full rounded-md bg-normal-pinflag p-2 text-sm text-white"
          onClick={() => window.location.reload()}
        >
          Listo
        </button>
      </div>
    )
  }

  return (
    <div className="m-2 flex flex-col p-4">
      {loading && <RawLoader />}
      <div className="mb-4">
        <label
          htmlFor="newId"
          className="mb-2 block text-sm font-medium text-gray-700"
        >
          Nueva orden de venta
        </label>
        <input
          id="newId"
          type="text"
          value={newId}
          onChange={(e) => {
            setError('')
            setNewId(e.target.value)
          }}
          placeholder={defaultOrderId}
          className="w-full rounded-md border border-gray-300 p-2 text-sm focus:border-pinflag focus:outline-none"
        />
        {error && (
          <p className="mt-1 text-sm text-red">{error}</p>
        )}
      </div>
      <button
        type="button"
        className="w-full rounded-md bg-normal-pinflag p-2 text-sm text-white"
        onClick={handleDuplicateOrder}
        disabled={loading}
      >
        <div>Duplicar orden</div>
      </button>
    </div>
  )
}

export default DuplicateOrder
