import {
  PACKAGE_BOUGHT,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_ON_RETURN,
  PACKAGE_PICKED_UP,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED,
  PACKAGE_RETURNED,
  PACKAGE_STORED,
  PACKAGE_TOTAL_INCIDENT,
  PACKAGE_DELIVERED,
  PACKAGE_CANCELLED
} from '../constants/packageStatus'
import ROUTES from '../constants/routes'
import { PACKAGE_TYPES } from '../constants/sales'
import { getSales } from './request/sales'

export const identifierCreator = (company) => {
  let identifier = ''
  if (company) identifier = company.slice(0, 3)
  return identifier.toUpperCase()
}

export const toBackStatus = (status) => {
  if (status === PACKAGE_RECEIVED){
    return [PACKAGE_RECEIVED, PACKAGE_STORED, PACKAGE_PICKED_UP]
  }

  return status
}

export const saleStatusColor = (status) => {
  if ([PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(status)) return 'bg-[#77A6FF1A] text-[#77A6FF]'

  if (
    [
      PACKAGE_IN_TRANSIT,
      PACKAGE_ON_DELIVERY,
      PACKAGE_RECEIVED,
      PACKAGE_STORED,
      PACKAGE_PICKED_UP
    ].includes(status)
  ) {
    return 'bg-[#ECFDF3] text-[#1F8E3D]'
  }

  if (status === PACKAGE_DELIVERED) return 'bg-[#1F8E3D] text-white'

  if ([PACKAGE_ON_RETURN, PACKAGE_RETURNED].includes(status)) return 'bg-[#FFF2DF80] text-[#CF7F30]'

  if ([PACKAGE_TOTAL_INCIDENT, PACKAGE_CANCELLED].includes(status)) return 'bg-[#FF77771A] text-[#FF7777]'

  return ''
}

export const saleStatusColorText = (status) => {
  if ([PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(status)) return 'text-[#3B9DF8]'

  return 'text-[#1F8E3D]'
}

export const saleStatusIconColor = (status) => {
  if ([PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(status)) return 'bg-[#E9F5FF] border-[#3B9DF8]'

  if (
    [
      PACKAGE_IN_TRANSIT,
      PACKAGE_ON_DELIVERY,
      PACKAGE_RECEIVED,
      PACKAGE_STORED,
      PACKAGE_PICKED_UP
    ].includes(status)
  ) {
    return 'bg-[#E9F5FF] border-[#1F8E3D]'
  }

  return 'bg-[#1F8E3D] border-[#1F8E3D]'
}

export const statusPointColor = (sale) => {
  if (sale.addressErrorData) {
    return 'bg-yellow'
  }
  if (sale.incident?.solved === false) {
    return 'bg-red'
  }
  if (sale.deliveryDelayStatus.includes('delayed')) {
    return 'bg-yellow'
  }
  return 'bg-green'
}

export const statusPointText = (sale) => {
  if (sale.addressErrorData) {
    return 'Pedido con error en la dirección'
  }
  if (sale.incident?.solved === false) {
    return 'Pedido con incidente'
  }
  if (sale.deliveryDelayStatus.includes('delayed')) {
    return 'Pedido atrasado'
  }
  return 'Pedido en tiempo'
}

export const productsQuantity = (products) => {
  let quantity = 0
  for (const product of products) {
    quantity += product.quantity
  }
  return quantity
}

export const getPreviousRoute = (
  location,
  packageTypeName = 'ecommerce',
  packageStatus = PACKAGE_BOUGHT,
  fromOrderWithError = false
) => {
  if (location.state?.previousRoute) return location.state.previousRoute

  const status = location.state?.status?.join('_') || packageStatus
  const page = location.state?.page
  const pageSize = location.state?.pageSize
  const packageType = location.state?.packageType || packageTypeName

  return `${ROUTES.SALES}?status=${status}${page ? `&page=${page}` : ''}
  ${pageSize ? `&pageSize=${pageSize}` : ''}
  ${fromOrderWithError ? '' : `&packageType=${PACKAGE_TYPES.indexOf(packageType)}`}`
}

export const getPreviousDistributionRoute = (location, packageStatus = PACKAGE_BOUGHT) => {
  const status = location.state?.status?.join('_') || packageStatus
  const page = location.state?.page
  const pageSize = location.state?.pageSize

  return `${ROUTES.DISTRIBUTION}?tab=0&status=${status}${page ? `&page=${page}` : ''}${
    pageSize ? `&pageSize=${pageSize}` : ''
  }`
}

export const groupProductsBySku = (products) => {
  const groupedProducts = {}
  for (const product of products) {
    if (groupedProducts[product.sku]) {
      groupedProducts[product.sku].quantity += product.quantity
    } else {
      groupedProducts[product.sku] = { ...product }
    }
  }
  return Object.values(groupedProducts)
}

export const validateProductForm = (product) => {
  const errorsForm = {}
  if (!product.name?.trim()) {
    errorsForm.name = 'El campo \'Nombre\' es requerido'
  }
  if (!product.weight?.trim()) {
    errorsForm.weight = 'El campo \'Peso\' es requerido'
  }
  if (!product.price?.trim()) {
    errorsForm.price = 'El campo \'Precio\' es requerido'
  }
  if (!product.code?.trim()) {
    errorsForm.code = 'El campo \'SKU\' es requerido'
  }
  return errorsForm
}

export const fetchSales = async (paramsFetch, signal) => {
  if (paramsFetch.page === 0) return null
  try {
    const response = await getSales({params: paramsFetch, signal})
    return response
  } catch (error) {
    if (!signal.aborted) {
      console.error('Error fetching data:', error)
    }
  }
  return null
}

export const fetchActualSales = async (signal, setActualPage, setActualFilteredSales) => {
  const newParams = JSON.parse(localStorage.getItem('params'))
  const response = await fetchSales(newParams, signal)
  if (response) {
    setActualPage(newParams.page)
    setActualFilteredSales(response.sales)
  }
}

export const setPreviousDataSales = async (params, setPreviousFilteredSales) => {
  try {
    if (params.page !== 1) {
      const newParams = { ...params, page: params.page - 1 }
      const previousResponse = await getSales({params: newParams})
      if (previousResponse) {
        setPreviousFilteredSales(previousResponse.sales)
      }
    }
  } catch (error) {
    console.error('Error initializing data:', error)
  }
}

export const setNextDataSales = async (params, setNextFilteredSales) => {
  try {
    const newParams = { ...params, page: params.page + 1 }
    const nextResponse = await getSales({params: newParams})
    if (nextResponse) {
      setNextFilteredSales(nextResponse.sales)
    }
  } catch (error) {
    console.error('Error initializing data:', error)
  }
}

export const setNextSaleFunction = (index, params, nextFilteredSales, setNextSale,
  actualFilteredSales) => {
  if (index === (params.pageSize - 1)) {
    if (nextFilteredSales && nextFilteredSales.length > 0) {
      setNextSale(nextFilteredSales[0].packageId)
    } else {
      setNextSale(null)
    }
  } else {
    try {
      setNextSale(actualFilteredSales[index + 1].packageId)
    } catch {
      setNextSale(null)
    }
  }
}

export const setPreviousSaleFunction = (index, previousFilteredSales, setPreviousSale,
  params, actualFilteredSales
) => {
  if (index === 0) {
    if (previousFilteredSales && params.page !== 1) {
      setPreviousSale(previousFilteredSales[params.pageSize - 1].packageId)
    } else {
      setPreviousSale(null)
    }
  } else {
    setPreviousSale(actualFilteredSales[index - 1].packageId)
  }
}

export const getNewSales = async (params, signal, isPrevious) => {
  const newParams = { ...params, page: isPrevious ? params.page - 2 : params.page + 2 }
  const response = await fetchSales(newParams, signal)
  return response
}

export const setNewValuesAfterResponse = (setActualPage, actualPage, setFilteredData,
  response, isPrevious
) => {
  setActualPage(isPrevious ? actualPage - 1 : actualPage + 1)
  if (response && response.length > 0) {
    setFilteredData(response.sales)
  } else {
    setFilteredData(null)
  }
}

export const setNewParamsFunction = (setParams, params, isPrevious) => {
  const newParams = {
    ...params,
    page: isPrevious ? params.page - 1 : params.page + 1
  }
  setParams(newParams)
}

export const setNullNeighbordsValues = (setPreviousSale, setNextSale) => {
  setPreviousSale(null)
  setNextSale(null)
}
