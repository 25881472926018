import React, { createContext, useState, useMemo, useEffect } from 'react'

import randomApi from '../axiosConfig/randomApi'
import { TICKETS_URL } from '../constants/urls'

export const TicketsContext = createContext()

const TicketsProvider = ({ children }) => {
  const [allFilteredTickets, setAllFilteredTickets] = useState(() => {
      const storedTickets = localStorage.getItem('tickets')
      return storedTickets ? JSON.parse(storedTickets) : null
    })
  const [actualTicket, setActualTicket] = useState(null)
  const [previousTicket, setPreviousTicket] = useState(null)
  const [nextTicket, setNextTicket] = useState(null)

  useEffect(() => {
    if (!actualTicket || !allFilteredTickets || !allFilteredTickets.length) {
      setPreviousTicket(null)
      setNextTicket(null)
      return
    }
    const index = allFilteredTickets.findIndex(ticket => ticket.id === actualTicket)

    const prev = index > 0 ? allFilteredTickets[index - 1] : null
    const next = index < allFilteredTickets.length - 1 ? allFilteredTickets[index + 1] : null

    if (prev) {
      setPreviousTicket(prev.id)
    } else {
      setPreviousTicket(null)
    }
    if (next){
      setNextTicket(next.id)
    } else {
      setNextTicket(null)
    }
  }, [actualTicket, allFilteredTickets])

  useEffect(() => {
    if (!actualTicket || allFilteredTickets) return undefined

    const controller = new AbortController()
    const { signal } = controller

    const fetchTickets = async () => {
      try {
        const response = await randomApi().get(TICKETS_URL, { signal })
        setAllFilteredTickets(response.data)
      } catch (err) {
        if (!signal.aborted) {
          console.error(err)
        }
      }
    }
    fetchTickets()

    return () => controller.abort()
  }, [actualTicket, allFilteredTickets])

  useEffect(() => {
    if (allFilteredTickets) {
      localStorage.setItem('tickets', JSON.stringify(allFilteredTickets))
    }
  }, [allFilteredTickets])

  const value = useMemo(
    () => ({
      allFilteredTickets,
      setAllFilteredTickets,
      actualTicket,
      setActualTicket,
      previousTicket,
      setPreviousTicket,
      nextTicket,
      setNextTicket
    }),
    [actualTicket, allFilteredTickets, nextTicket, previousTicket]
  )

  return (
    <TicketsContext.Provider value={value}>
      {children}
    </TicketsContext.Provider>
  )
}

export default TicketsProvider
