import { React, useState, useContext, useEffect } from 'react'

import { SEND_MESSAGE_URL } from '../../../constants/urls'
import { formatDate } from '../../../utils/dates'
import randomApi from '../../../axiosConfig/randomApi'
import { TICKET_ICONS } from '../../../constants/icons'
import { notificationContext } from '../../../contexts/NotificationContext'

const Chat = ({ chatHistory, ticketId, closingMessage, unreadMessages }) => {
  const [history, setHistory] = useState(
    [...chatHistory].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  )
  const [messageTosend, setMessageToSend] = useState('')
  const [localUnreadMessages, setLocalUnreadMessages] = useState(unreadMessages)

  const { setTicketsMessages } = useContext(notificationContext)

  const unreadIndices = [...Array(localUnreadMessages).keys()].map(
    (i) => history.length - localUnreadMessages + i
  )

  useEffect(() => {
    setLocalUnreadMessages(unreadMessages)
  }, [unreadMessages])

  const sendMessage = async () => {
    if (messageTosend.trim() === '') return
    const body = {
      message: messageTosend,
      sender: 'ecommerce'
    }
    const response = await randomApi().post(SEND_MESSAGE_URL(ticketId), body)
    setHistory(history.concat(response.data))
    setTicketsMessages((prev) => ({
      ...prev,
      [ticketId]: 0
    }))
    setLocalUnreadMessages(0)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    sendMessage()
    setMessageToSend('')
  }

  return (
    <div className="flex flex-col h-full w-full bg-light-grey rounded-lg p-2">
      <div className="flex-1 overflow-y-auto">
        {history.length === 0 && (
          <div className="text-center text-medium-grey my-4">Aún no hay mensajes</div>
        )}
        {history.map((message, index) => (
          <div
            key={message.createdAt}
            className={`${
              message.sender === 'ecommerce' ? 'ml-auto mr-2 text-end' : 'mr-auto ml-2'
            } w-fit max-w-[65%] mt-2`}
          >
            <div
              className={`w-fit py-2 px-4 overflow-hidden break-words 
              text-sm rounded-b-2xl ${
                message.sender === 'ecommerce'
                  ? 'mr-0 ml-auto bg-normal-pinflag text-white rounded-tl-2xl'
                  : 'ml-0 mr-auto bg-white rounded-tr-2xl'
              }`}
            >
              {message.message}
            </div>
            <div className="flex items-center gap-2 text-xs text-dark-grey">
              {unreadIndices.includes(index) && (
                <div className="w-2 h-2 bg-red rounded-full" />
              )}
              {formatDate(message.createdAt)}
            </div>
          </div>
        ))}
        {closingMessage && (
          <div className="m-2 border-t px-4 pt-2 text-s text-medium-dark-grey">
            <span className="italic">{closingMessage}</span> - Estamos aquí para ayudarte. Si surge
            alguna pregunta adicional, no dudes en contactarnos. ¡Que tengas un excelente día!
          </div>
        )}
      </div>
      <form className="relative m-2" onSubmit={handleSubmit}>
        <textarea
          className="rounded-2xl p-3 pr-6 w-full shadow-lg text-sm break-words resize-none h-fit"
          placeholder="Escribe un mensaje"
          value={messageTosend}
          onChange={(event) => setMessageToSend(event.target.value)}
          maxLength="500"
        />
        <button type="submit" className="absolute bottom-4 right-2">
          <img src={TICKET_ICONS.send} alt="enviar" className="h-6 py-1" />
        </button>
      </form>
    </div>
  )
}

export default Chat
