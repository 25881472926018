import React, { createContext, useState, useMemo, useEffect, useCallback } from 'react'

import { fetchActualSales, setPreviousDataSales, setNextDataSales,
  setNextSaleFunction, setPreviousSaleFunction, getNewSales, setNewValuesAfterResponse,
  setNewParamsFunction, setNullNeighbordsValues
 } from '../helpers/sales'

export const SalesContext = createContext()

const SalesProvider = ({ children }) => {
  const [actualFilteredSales, setActualFilteredSales] = useState(null)
  const [actualSale, setActualSale] = useState(null)
  const [previousSale, setPreviousSale] = useState(null)
  const [nextSale, setNextSale] = useState(null)
  const [params, setParams] = useState(() => {
    const storedParams = localStorage.getItem('params')
    return storedParams ? JSON.parse(storedParams) : null
  })
  const [actualPage, setActualPage] = useState(null)
  const [actualIndex, setActualIndex] = useState(null)
  const [previousFilteredSales, setPreviousFilteredSales] = useState(null)
  const [nextFilteredSales, setNextFilteredSales] = useState(null)
  const [changeNeighbors, setChangeNeighbors] = useState(false)
  const [allPreviousRoute, setAllPreviousRoute] = useState(() => {
    const storedRoute = localStorage.getItem('route')
    return storedRoute || null
  })

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    const initializeData = async () => {
      if (!actualFilteredSales) {
        fetchActualSales(signal, setActualPage, setActualFilteredSales)
      }
      if (!actualFilteredSales || actualFilteredSales.length === 0 ) {
        return
      }
      setPreviousDataSales(params, setPreviousFilteredSales)
      setNextDataSales(params, setNextFilteredSales)
    }

    initializeData()

    return () => {
      controller.abort()
    }
  }, [actualFilteredSales, actualPage, params])


  useEffect(() => {
    if (params) {
      localStorage.setItem('params', JSON.stringify(params))
    }
  }, [params])

  useEffect(() => {
    if (allPreviousRoute) {
      localStorage.setItem('route', allPreviousRoute)
    }
  }, [allPreviousRoute])

  const movePreviousSales = useCallback(async () => {
    const controller = new AbortController()
    const { signal } = controller
    setNextFilteredSales(actualFilteredSales)
    setActualFilteredSales(previousFilteredSales)
    const isPrevious = true
    const response = await getNewSales(params, signal, isPrevious)
    setNewValuesAfterResponse(setActualPage, actualPage, setPreviousFilteredSales, response,
      isPrevious)
    setNewParamsFunction(setParams, params, isPrevious)
  }, [actualFilteredSales, actualPage, params, previousFilteredSales])

  const moveNextSales = useCallback(async () => {
    const controller = new AbortController()
    const { signal } = controller
    setPreviousFilteredSales(actualFilteredSales)
    setActualFilteredSales(nextFilteredSales)
    const isPrevious = false
    const response = await getNewSales(params, signal, isPrevious)
    setNewValuesAfterResponse(setActualPage, actualPage, setNextFilteredSales, response, isPrevious)
    setNewParamsFunction(setParams, params, isPrevious)
  }, [actualFilteredSales, actualPage, nextFilteredSales, params])

  useEffect(() => {
    if (!actualSale) {
      setNullNeighbordsValues(setPreviousSale, setNextSale)
      return
    }
    if (!actualFilteredSales) return

    let index = actualFilteredSales.findIndex(sale => sale.packageId === actualSale.packageId)
    if (index === -1) {
      if (previousFilteredSales) {
        index = previousFilteredSales.findIndex(sale => sale.packageId === actualSale.packageId)
      }
      if (index === -1) {
        index = nextFilteredSales.findIndex(sale => sale.packageId === actualSale.packageId)
        moveNextSales()
      } else {
        movePreviousSales()
      }
    }
    setActualIndex(index)

    if (params === null) return

    setChangeNeighbors(true)

  }, [actualFilteredSales, actualIndex, actualPage, actualSale, moveNextSales,
    movePreviousSales, nextFilteredSales, params, previousFilteredSales])

  useEffect(() => {
    if (changeNeighbors){
      setPreviousSaleFunction(actualIndex, previousFilteredSales, setPreviousSale, params,
        actualFilteredSales)
      setNextSaleFunction(actualIndex, params, nextFilteredSales, setNextSale, actualFilteredSales)
      setChangeNeighbors(false)
    }
  }, [actualFilteredSales, actualIndex, changeNeighbors, nextFilteredSales, params,
    previousFilteredSales])

  const value = useMemo(
    () => ({
      actualFilteredSales,
      setActualFilteredSales,
      actualSale,
      setActualSale,
      previousSale,
      setPreviousSale,
      nextSale,
      setNextSale,
      setParams,
      setActualIndex,
      allPreviousRoute,
      setAllPreviousRoute
    }),
    [actualFilteredSales, actualSale, previousSale, nextSale, allPreviousRoute]
  )

  return (
    <SalesContext.Provider value={value}>
      {children}
    </SalesContext.Provider>
  )
}

export default SalesProvider
