import React, { useState, useContext, useCallback, useEffect } from 'react'

import { PixelCredentialsContext } from '../../../contexts/PixelCredentialsContext'
import CredentialsUpdateWindow from '../../../components/Settings/Integrations/CredentialsUpdateWindow'
import ErrorSaveCredentialsModal from '../../../components/Settings/Integrations/ErrorSaveCredentialsModal'
import IntegrationResume from '../../../components/Settings/Integrations/IntegrationResume'
import SuccessSaveCredentialsModal from '../../../components/Settings/Integrations/SuccessSaveCredentialsModal'
import useSubmitPixelCredentials from '../../../components/Settings/useSubmitPixelCredentials'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import SectionHeader from '../../../components/shared/SectionHeader2'
import { GA4_PIXEL_CREDENTIALS_IMAGES } from '../../../constants/ga4PixelCredential'
import { storeIcon, metricsIcon } from '../../../constants/icons'
import { TITLE, SUBTITLE, INTEGRATIONS_TEXTS } from '../../../constants/integrationWelcome'
import { MULTIVENDE_IMAGES } from '../../../constants/multivende'
import { META_PIXEL_CREDENTIALS_IMAGES } from '../../../constants/metaPixelCredential'
import ROUTES from '../../../constants/routes'
import { parsePixelCredentials, getPixelNameByIntegrationName, initializeAllPixels,
  setPixelCredentials } from '../../../helpers/pixelCredentials'
import { getPixelCredentials } from '../../../helpers/request/stats'

const Integrations = () => {
  const [isSidebarOpenGA4, setIsSidebarOpenGA4] = useState(false)
  const [isSidebarOpenMeta, setIsSidebarOpenMeta] = useState(false)
  const [sidebarContent, setSidebarContent] = useState('')
  const [isLoading, setIsLoading] = useState('')

  const {
    companyId,
    pixels,
    setPixels,
    areCredentialsLoaded,
    setAreCredentialsLoaded
  } = useContext(PixelCredentialsContext)

  const { onSubmit, openResponseModal, setOpenResponseModal, loadingSubmit, errorSubmit } =
  useSubmitPixelCredentials()

  const loadPixelCredentials = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await getPixelCredentials({ params: companyId })
      if (data) initializeAllPixels(data, setPixels)
      setAreCredentialsLoaded(true)
    } catch (error) {
      console.error('Error fetching pixel credentials:', error)
    } finally {
      setIsLoading(false)
    }
  }, [companyId, setAreCredentialsLoaded, setPixels])

  useEffect(() => {
    if (areCredentialsLoaded === null){
      loadPixelCredentials()
    }
  }, [areCredentialsLoaded, loadPixelCredentials])

  const handlePixelToggle = async (integrationName, isActive) => {
    const pixelName = getPixelNameByIntegrationName(integrationName)
    const parseCredentials = parsePixelCredentials(
      companyId,
      pixels[pixelName].credentials,
      pixelName,
      isActive
    )

    await onSubmit(parseCredentials, isActive, setPixels)
  }

  const openSidebarGA4 = (content) => {
    setSidebarContent(content)
    setIsSidebarOpenGA4(true)
  }

  const closeSidebarGA4 = () => {
    setIsSidebarOpenGA4(false)
  }

  const openSidebarMeta = (content) => {
    setSidebarContent(content)
    setIsSidebarOpenMeta(true)
  }

  const closeSidebarMeta = () => {
    setIsSidebarOpenMeta(false)
  }

  const handleModalClose = () => {
    setOpenResponseModal(false)
  }

  const handleModalCloseError = () => {
    setOpenResponseModal(false)
  }

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-900 z-50">
        <RawLoader />
      </div>
    )
  }

  return (
    <div className="flex flex-col h-screen">
      <SectionHeader title="Integraciones" />
      <div className="bg-general-background py-8 px-16 grow">
        <div className="flex flex-col justify-between bg-white p-12 rounded-lg h-max">
          <div>
            <div className="pb-4 text-lg font-semibold text-ultra-dark-grey">
              {TITLE}
            </div>
            <div className="py-2 mb-8">
              {SUBTITLE}
            </div>
            <div className="inline-flex bg-gray-200 rounded-xl py-2 px-4 items-center">
              <img className="w-5 h-5 mr-2" alt="icon" src={storeIcon} />
              <div className="text-sm text-ultra-dark-grey font-semibold whitespace-nowrap">Marketplace</div>
            </div>
            <div className="flex flex-row gap-x-10">
              <IntegrationResume
                logoImages={[
                  { src: MULTIVENDE_IMAGES.logoNoText, alt: INTEGRATIONS_TEXTS.multivende_name, className: 'w-14' }
                ]}
                integrationName={INTEGRATIONS_TEXTS.multivende_name}
                integrationText={INTEGRATIONS_TEXTS.multivende_text}
                integrationPath={ROUTES.MULTIVENDE}
                isCredentialsSave
              />
            </div>
            <div className="inline-flex bg-gray-200 rounded-xl py-2 px-4 items-center mt-4">
              <img className="w-5 h-5 mr-2" alt="icon" src={metricsIcon} />
              <div className="text-sm text-ultra-dark-grey font-semibold whitespace-nowrap">Métricas</div>
            </div>
            <div className="flex flex-row gap-x-10">
              <IntegrationResume
                logoImages={[
                  { src: GA4_PIXEL_CREDENTIALS_IMAGES.analytics, alt: INTEGRATIONS_TEXTS.ga4_name, className: 'w-30' },
                  { src: GA4_PIXEL_CREDENTIALS_IMAGES.ads, alt: INTEGRATIONS_TEXTS.ga4_name, className: 'w-50' }
                ]}
                integrationName={INTEGRATIONS_TEXTS.ga4_name}
                integrationText={INTEGRATIONS_TEXTS.ga4_text}
                integrationPath={ROUTES.GA4}
                isCredentialsSave={pixels.ga4.hasCredentialsSave}
                isPixelActive={pixels.ga4.isActive}
                openSidebar={openSidebarGA4}
                onToggleSwitch={handlePixelToggle}
              />
              <IntegrationResume
                logoImages={[
                  { src: META_PIXEL_CREDENTIALS_IMAGES.meta, alt: INTEGRATIONS_TEXTS.meta_text, className: 'w-50' }
                ]}
                integrationName={INTEGRATIONS_TEXTS.meta_name}
                integrationText={INTEGRATIONS_TEXTS.meta_text}
                integrationPath={ROUTES.META}
                isCredentialsSave={pixels.facebook.hasCredentialsSave}
                isPixelActive={pixels.facebook.isActive}
                openSidebar={openSidebarMeta}
                onToggleSwitch={handlePixelToggle}
              />
            </div>
          </div>
        </div>
      </div>
      {loadingSubmit && (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-900 z-50">
          <RawLoader />
        </div>
      )}
      {errorSubmit && (
        <ErrorSaveCredentialsModal show={openResponseModal} handleClose={handleModalCloseError}/>
      )}
      {!loadingSubmit && !errorSubmit && (
        <SuccessSaveCredentialsModal show={openResponseModal} handleClose={handleModalClose}
        isFirstTime={false} isActiveChange/>
      )}
      <CredentialsUpdateWindow isOpen={isSidebarOpenGA4} credentialsContent={sidebarContent}
      handleClose={closeSidebarGA4}
      inputValues={pixels.ga4.credentials}
      setInputValues={(newValues) =>
      setPixelCredentials(INTEGRATIONS_TEXTS.ga4_pixel_name, newValues, setPixels)}/>
      <CredentialsUpdateWindow isOpen={isSidebarOpenMeta} credentialsContent={sidebarContent}
      handleClose={closeSidebarMeta}
      inputValues={pixels.facebook.credentials}
      setInputValues={(newValues) => setPixelCredentials(INTEGRATIONS_TEXTS.meta_pixel_name,
      newValues, setPixels)}/>
    </div>
  )
}

export default Integrations
