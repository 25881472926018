import { React, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrayParam, NumberParam, useQueryParam } from 'use-query-params'

import PaginationFooter from '../../components/shared/Pagination/PaginationFooter'
import SearchBar from '../../components/shared/SearchBar'
import useFetch from '../../hooks/useFetchParams'
import SectionHeader from '../../components/shared/SectionHeader'
import { SIDEBAR_BUTTONS } from '../../constants/general'
import OrderGeneratorButton from '../../components/Sales/SalesList/SalesTable/OrderGeneratorButton'
import SalesTable from '../../components/Sales/SalesList/SalesTable/SalesTable'
import SalesFilters from '../../components/Sales/SalesList/SalesFilters/SalesFilters'
import DropDownHeader from '../../components/Sales/SalesList/DropDownHeader'
import { ACTIONS } from '../../constants/icons'
import SalesSelection from '../../components/Sales/SalesList/SelectionBar/SalesSelection'
import TabBar from '../../components/shared/TabBar/TabBar'
import {
  PACKAGE_TYPES,
  CLICK_AND_COLLECT_STATES,
  HOME_DELIVERY_STATES,
  OTHERS_STATUS
} from '../../constants/sales'
import ExportSales from '../../components/Sales/SalesList/ExportSales'
import { saleStatusColor, setNullNeighbordsValues } from '../../helpers/sales'
import { getSales } from '../../helpers/request/sales'
import { getStates } from '../../helpers/request/states'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import ToolTip from '../../components/shared/ToolTip'
import NoAccess from '../../components/NoAccess'
import { AuthContext } from '../../contexts/Store'
import { SalesContext } from '../../contexts/SalesContext'

const Sales = () => {
  const [sales, setSales] = useState([])
  const [salesWithError, setSalesWithError] = useState([])
  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [selectedStatus = [], setSelectedStatus] = useQueryParam('status', ArrayParam)
  const [selectedSales, setSelectedSales] = useState([])
  const [selectedToOwnPoint, setSelectedToOwnPoint] = useState([])
  const [page = 1, setPage] = useQueryParam('page', NumberParam)
  const [pageSize = 20, setPageSize] = useQueryParam('pageSize', NumberParam)
  const [isFiltered, setIsFiltered] = useState(false)
  const [packageType = 0, setPackageType] = useQueryParam('packageType', NumberParam)
  const [showArchivedSales, setShowArchivedSales] = useState(false)
  const [filtersData, setFiltersData] = useState({})
  const [appliedFilters, setAppliedFilters] = useState({})
  const [order, setOrder] = useState('')
  const [states, setStates] = useState({})

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const { setParams, setActualFilteredSales, setActualIndex,
    setPreviousSale, setNextSale, setActualSale } = useContext(SalesContext)

  useFetch(getStates, setStates)

  const setSalesData = useCallback((data) => {
    setSales(data.sales)
    if(data.salesWithError && data.salesWithError.length) setSalesWithError(data.salesWithError)
    if(data.filters) setFiltersData(data.filters)
  }, [])

  const type = PACKAGE_TYPES[packageType]

  const statusParam = selectedStatus.join(',')

  const params = useMemo(
    () => ({
      archived: showArchivedSales,
      packageType: type,
      page,
      pageSize,
      ...appliedFilters,
      status: statusParam || undefined,
      searchTerm: searchTerm || undefined,
      order: order || undefined
    }), [
      showArchivedSales, type, page, pageSize, appliedFilters, statusParam, searchTerm, order
    ]
  )
  params.getFilters = Object.keys(filtersData).length === 0 || undefined

  const { isLoading, error, abortController } = useFetch(
    getSales,
    setSalesData,
    params
  )

  useEffect(() => {
    setSales([])
    return () => {
      if (abortController) abortController.abort()
      setSales([])
    }
  }, [abortController])

  const searchHandler = (newSearchTerm) => {
    setSearchBarInput(newSearchTerm)
    const searchTermList = newSearchTerm.split(',').map(term => term.trim())
    const uncompletedElementExists = searchTermList.some(term => term.length < 3)
    if (newSearchTerm.length === 0 || !uncompletedElementExists) {
      clearTimeout(searchTimeout)
      const newTimeout = setTimeout(() => {
        setPage(1)
        setSearchTerm(newSearchTerm)
      }, 300)
      setSearchTimeout(newTimeout)
    }
  }

  const handleSort = (field, direction) => {
    setPage(1)
    if (direction === 'asc') {
      setOrder(field)
    } else {
      setOrder(`-${field}`)
    }
  }

  const changePackageType = (newType) => {
    setPage(1)
    setFiltersData({})
    setPackageType(newType)
  }

  const changeShowArchivedSales = (newValue) => {
    setPage(1)
    setShowArchivedSales(newValue)
  }

  const changePageSize = (newPageSize) => {
    setPage(1)
    setPageSize(newPageSize)
  }

  const changeAppliedFilters = (newFilters) => {
    setPage(1)
    setAppliedFilters(newFilters)
  }

  const changeSelectedStatus = (newStatus) => {
    setPage(1)
    setSelectedStatus(newStatus)
  }

  const statusFilters = packageType === 0 ? CLICK_AND_COLLECT_STATES : HOME_DELIVERY_STATES

  useEffect(() => {
    setParams(params)
    setActualIndex(0)
    setNullNeighbordsValues(setPreviousSale, setNextSale)
    setActualSale(null)
    setActualFilteredSales((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(sales)) {
        return sales
      }
      return prev
    })
  }, [params, sales, setActualFilteredSales, setActualIndex, setActualSale,
    setNextSale, setParams, setPreviousSale])

  if (!hasAccess('sales')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  return (
    <div className="relative h-screen flex flex-col bg-light-grey">
      <SectionHeader
        dropdown={
          <DropDownHeader
            title={showArchivedSales ? 'Ventas archivadas' : 'Ventas'}
            icon={showArchivedSales ? ACTIONS.archive : SIDEBAR_BUTTONS.sales.inactiveIcon}
            dropDownItem={
              <div
                className="flex w-max gap-2 p-3"
                onClick={() => changeShowArchivedSales(!showArchivedSales)}
                tabIndex="0"
                role="button"
              >
                <img
                  src={showArchivedSales ? SIDEBAR_BUTTONS.sales.inactiveIcon : ACTIONS.archive}
                  alt="ventas"
                  className="w-4 h-4 m-auto"
                />
                <div>{showArchivedSales ? 'Ventas' : 'Ventas archivadas'}</div>
              </div>
            }
          />
        }
        rightChildren={
          <TabBar tabs={['Propias', 'Multicanal']} tab={packageType} setTab={changePackageType} />
        }
        searchBar={
          <ToolTip
            backgroundColor="bg-ultra-dark-grey"
            textColor="text-white"
            hoverElement={
              <SearchBar
                searchbarInput={searchBarInput}
                searchKeyword={searchHandler}
              />
            }
            right
          >
            <div className="max-w-[13rem]">
              Ahora puedes buscar parámetros que no se encuentren en la tabla
            </div>
          </ToolTip>
        }
      />
      <ActionsBar
        statusFilters={showArchivedSales ? undefined : statusFilters}
        extraFilters={showArchivedSales ? undefined : OTHERS_STATUS}
        extraFiltersColor={saleStatusColor}
        selectedFilters={selectedStatus}
        setSelectedFilters={changeSelectedStatus}
        salesWithError={salesWithError}
        rightChildren={
          <div className="flex gap-1">
            {!isLoading && (
              <>
                <SalesFilters
                  filtersData={filtersData}
                  isFiltered={isFiltered}
                  setIsFiltered={setIsFiltered}
                  handleFilterChange={changeAppliedFilters}
                  showArchivedSales={showArchivedSales}
                  packageType={type}
                  selectedFilters={appliedFilters}
                />
                <ExportSales packageType={type} filtersData={filtersData} />
              </>
            )}
            <OrderGeneratorButton />
          </div>
        }
      />
      <SalesTable
        sales={sales}
        selectedSales={selectedSales}
        setSelectedSales={setSelectedSales}
        setSelectedToOwnPoint={setSelectedToOwnPoint}
        pageSize={pageSize}
        page={page}
        isLoading={isLoading || loadingResources}
        showArchivedSales={showArchivedSales}
        packageType={type}
        error={error}
        handleSort={handleSort}
        states={states}
      />
      <PaginationFooter
        page={page}
        pageSize={pageSize}
        pageItems={sales}
        setPage={setPage}
        setPageSize={changePageSize}
        totalItems={sales}
        useCount={false}
      />
      {!showArchivedSales && selectedSales.length > 0 && (
        <div className="absolute bottom-4 left-10">
          <SalesSelection
            selectedSales={selectedSales}
            selectedToOwnPoint={selectedToOwnPoint}
            status={selectedStatus}
            statusFromSelected={sales
              .filter((sale) => selectedSales.includes(sale.idbulto))
              .map((sale) => sale.status)}
            page={page}
            pageSize={pageSize}
            packageType={type}
            handleCloseBar={() => {
              setSelectedSales([])
              setSelectedToOwnPoint([])
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Sales
