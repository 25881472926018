import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { backBlackArrow } from '../../../constants/icons'
import { PIXEL_ID_NAME, ACCESS_TOKEN_NAME } from '../../../constants/integrationWelcome'
import { PixelCredentialsContext } from '../../../contexts/PixelCredentialsContext'
import { validateInputSavecredentials, parsePixelCredentials } from '../../../helpers/pixelCredentials'
import Button from '../../shared/Button'
import RawLoader from '../../shared/Loader/RawLoader'
import useSubmitPixelCredentials from '../useSubmitPixelCredentials'
import CredentialsUpdateWindowStyle from './CredentialsUpdateWindow.Style'
import CredentialValueWindow from './CredentialValueWindow'
import ErrorSaveCredentialsModal from './ErrorSaveCredentialsModal'
import SuccessSaveCredentialsModal from './SuccessSaveCredentialsModal'

const CredentialsUpdateWindow = ({ isOpen, handleClose, credentialsContent,
  inputValues, setInputValues
}) => {
  const [areValuesVerified, setAreValuesVerified] = useState(false)
  const [runAnimation, setRunAnimation] = useState(false)
  const [errorPixelId, setErrorPixelId] = useState('')
  const [errorAccessToken, setErrorAccessToken] = useState('')
  const [localInputs, setLocalInputs] = useState({
    pixelId: '',
    pixelAccessToken: ''
  })

  const { companyId, pixels, setPixels } = useContext(PixelCredentialsContext)

  const { onSubmit, openResponseModal, setOpenResponseModal, loadingSubmit, errorSubmit } =
  useSubmitPixelCredentials()

  const handleInputChange = (id, value) => {
    setLocalInputs((prevValues) => ({
      ...prevValues,
      [id]: value
    }))
  }

  const closeSidebar = () => {
    setRunAnimation(true)
    setTimeout(() => {
      setRunAnimation(false)
      handleClose()
      setErrorAccessToken('')
      setErrorPixelId('')
    }, 1000)
  }

  const clickOutside = (e) => {
    if (e.currentTarget !== e.target) return
    closeSidebar()
  }

  const validateInputValuePixelId = () => {
    const stepError = validateInputSavecredentials(localInputs, PIXEL_ID_NAME,
      credentialsContent.name)
    setErrorPixelId(stepError)
    if (stepError === '') return true
    return false
  }

  const validateInputValueAccessToken = () => {
    const stepError = validateInputSavecredentials(localInputs, ACCESS_TOKEN_NAME,
      credentialsContent.name)
    setErrorAccessToken(stepError)
    if (stepError === '') return true
    return false
  }

  const submitCredentials = async () => {
    const pixelKey = credentialsContent.pixel_name
    const pixelData = pixels[pixelKey]

    const parseCredentials = parsePixelCredentials(companyId, localInputs,
      credentialsContent.pixel_name, pixelData.isActive)

    await onSubmit(parseCredentials, pixelData.isActive, setPixels)

    setAreValuesVerified(true)
  }


  const handleSaveCredentials = () => {
    const isValidInputPixelId = validateInputValuePixelId()
    const isValidInputAccessToken = validateInputValueAccessToken()
    if (isValidInputPixelId && isValidInputAccessToken) {
      setInputValues(localInputs)
      submitCredentials()
    }
  }

  const handleModalClose = () => {
    setOpenResponseModal(false)
    closeSidebar()
  }

  const handleModalCloseError = () => {
    setOpenResponseModal(false)
  }

  useEffect(() => {
    if (isOpen) {
      setLocalInputs(inputValues || { pixelId: '', pixelAccessToken: '' })
    }
  }, [isOpen, credentialsContent, inputValues])

  return (
    <CredentialsUpdateWindowStyle>
      <div
        className={`sidebar-container ${!isOpen ? 'hidden' : ''} ${
          runAnimation ? 'fade-out' : ''
        }`}
        onClick={clickOutside}
        role="button"
        tabIndex="0"
      />
      <div
        className={`sidebar-modal ${isOpen ? '' : 'hidden'} ${
          runAnimation ? 'slide-out' : ''
        }`}
      >
      <div className="flex flex-col h-full overflow-auto">
        <div className="flex flex-row-reverse px-4 pt-4">
          <Button
            textColor="text-xl font-bold"
            onClick={closeSidebar}
          >
            X
          </Button>
        </div>
        <div className="flex justify-start items-end ml-4">
          <div className="h-14 mr-4">{credentialsContent.logo}</div>
          <span className="font-semibold text-2xl">Configuraciones</span>
        </div>
        <div className="flex flex-col mx-4 p-4 flex-grow">
          <div className="my-4">
            <span className="font-bold text-xl">{credentialsContent.title}</span>
          </div>
          <div className="mb-10 text-sm">{credentialsContent.subtitle}</div>
          <CredentialValueWindow
            credentialsContent={credentialsContent}
            inputValues={localInputs}
            handleInputChange={handleInputChange}
            idInput="pixelId"
            errorAccessToken={errorPixelId}
            title={credentialsContent.pixel_id_title}
            subtitle={credentialsContent.pixel_id_subtitle}
            areValuesVerified={areValuesVerified}
          />
          <CredentialValueWindow
            credentialsContent={credentialsContent}
            inputValues={localInputs}
            handleInputChange={handleInputChange}
            idInput="pixelAccessToken"
            errorAccessToken={errorAccessToken}
            title={credentialsContent.access_token_title}
            subtitle={credentialsContent.access_token_subtitle}
            areValuesVerified={areValuesVerified}
          />
          <div className="pb-4 text-sm">{credentialsContent.final_text}</div>
          <Link
            to={credentialsContent.onboarding_link}
            className="flex items-center text-sky-500 hover:underline"
          >
            <img
              alt="back-arrow"
              src={backBlackArrow}
              className="w-4 h-4 mr-2"
              style={{
                filter: 'brightness(0) saturate(100%) invert(46%) sepia(100%) saturate(939%) hue-rotate(166deg) brightness(95%) contrast(92%)'
              }}
            />
            Ver onboarding
          </Link>
        </div>
        <div className="mt-auto flex flex-row justify-end p-8 py-10 space-x-5">
          <Button
            border="border border-normal-pinflag"
            textColor="text-normal-pinflag"
            onClick={() => closeSidebar()}
          >
            Cancelar
          </Button>
          <Button
            border="border border-normal-pinflag"
            color="bg-normal-pinflag"
            onClick={() => handleSaveCredentials()}
          >
            Guardar cambios
          </Button>
        </div>
      </div>
      </div>

      {loadingSubmit && (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-900 z-50">
          <RawLoader />
        </div>
      )}
      {errorSubmit && (
        <ErrorSaveCredentialsModal show={openResponseModal} handleClose={handleModalCloseError}/>
      )}
      {!loadingSubmit && !errorSubmit && (
        <SuccessSaveCredentialsModal show={openResponseModal} handleClose={handleModalClose}
        isFirstTime={false}/>
      )}
    </CredentialsUpdateWindowStyle>
  )
}

export default CredentialsUpdateWindow
