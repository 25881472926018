import { React, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { NOTIFICATIONS_ICONS } from '../../../constants/icons'
import { notificationContext } from '../../../contexts/NotificationContext'
import { readNotificationHelper } from '../../../helpers/notifications'
import useNotifications from '../../../hooks/useNotifications'
import { getHourAndDate } from '../../../utils/dates'

import NotificationCardStyle from './NotificationCard.style'

const NotificationCard = ({ notification, waitCloseAnimation }) => {
  const { readNotificationsAndUpdate } = useNotifications()
  const { ticketsInfo } = useContext(notificationContext)
  const navigate = useNavigate()

  const getDefaultIcon = (category) => {
    switch (category) {
      case 'Sales':
        return NOTIFICATIONS_ICONS.sales
      case 'Tickets':
        return NOTIFICATIONS_ICONS.tickets
      case 'Requests':
        return NOTIFICATIONS_ICONS.requests
      default:
        return NOTIFICATIONS_ICONS.requests
    }
  }

  const handleOpen = async () => {
    if (!notification.read) {
      let notificationReaded = false
      if (notification.idCategory) {
        notificationReaded = readNotificationHelper(ticketsInfo, notification.idCategory,
          readNotificationsAndUpdate)
      } else {
        notificationReaded = readNotificationsAndUpdate(
          [notification.id],
          notification.payload.category
        )
      }

      if (notificationReaded) {
        waitCloseAnimation()
        navigate(notification.payload.action.url)
      }
    } else {
      waitCloseAnimation()
      navigate(notification.payload.action.url)
    }
  }

  return (
    <NotificationCardStyle>
      <div
        className={`notification-card-container ${
          !notification.read && 'notification-card-container-readed'
        }`}
        onClick={handleOpen}
        role="button"
        tabIndex="0"
      >
        <div className="notification-card-container-info">
          <div className="notification-card-icon">
            {notification.payload.icon ? (
              <img src={notification.payload.icon} alt="icon" />
            ) : (
              <img src={getDefaultIcon(notification.category)} alt="icon" />
            )}
          </div>
          <div className="notification-card-info">
            <div className="notification-card-info-title">{notification.payload.title}</div>
            <div className="notification-card-info-message">{notification.payload.message}</div>
          </div>
          <div className="notification-card-time">{getHourAndDate(notification.createdAt)}</div>
        </div>
      </div>
    </NotificationCardStyle>
  )
}

export default NotificationCard
