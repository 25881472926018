import React, { useCallback, useMemo, useState } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'
import { useNavigate } from 'react-router-dom'

import SearchBar from '../../shared/SearchBar'
import { isSubstring } from '../../../utils/strings'
import useFetch from '../../../hooks/useFetchParams'
import { sortArrayByKey } from '../../../utils/arrays'
import { searchTermsGenerator } from '../../../utils/filters'
import { Table, TableRow } from '../../shared/Table/Table'
import PaginationFooter from '../../shared/Pagination/PaginationFooter'
import { getWarehouses } from '../../../helpers/request/warehouse'
import ROUTES from '../../../constants/routes'

const Stores = () => {
  const [stores, setStores] = useState([])
  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [page = 1, setPage] = useQueryParam('page', NumberParam)
  const [pageSize = 20, setPageSize] = useQueryParam('pageSize', NumberParam)

  const navigate = useNavigate()

  const params = useMemo(() => ({ type: 'store' }), [])

  const setStoreData = useCallback((data) => {
    setStores(data)
    setSearchResult(data)
  }, [])

  const { isLoading, error } = useFetch(getWarehouses, setStoreData, params)

  const searchBarFilter = (storesList, searchTerm) => {
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const searchedSales = storesList.filter(
        (sale) => searchTerms.some((term) => (
          isSubstring(sale.companyName, term) ||
          isSubstring(sale.customerEmail, term) ||
          isSubstring(sale.codbultocliente, term)
        ))
      )
      setSearchResult(searchedSales)
    } else {
      setSearchResult(stores)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    const storesList = stores
    searchBarFilter(storesList, searchTerm)
  }


  const handleSort = (sortValues, order) => {
    const sortedCurrentSales = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedCurrentSales)
    const sortedSales = sortArrayByKey(stores, sortValues, order)

    setStores(sortedSales)
  }

  const sortValues = {
    ID: { key: 'storeId', type: 'string' },
    Tienda: { key: 'name', type: 'string' }
  }

  const currentStores = searchResult.slice(pageSize * (page - 1), pageSize * page)

  return (
    <div>
      <div className="mx-10 mt-4 bg-white border-t border-x px-4 py-2">
        <div className="w-52">
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        </div>
      </div>
      {!isLoading && !error && currentStores.length === 0 ? (
        <div className="mx-10 border text-center py-8 bg-white text-s">
          No hay tiendas con este criterio
        </div>
      ) : (
        <Table
          maxHeight="max-h-[60vh]"
          sortValues={sortValues}
          sortData={handleSort}
          headers={['ID', 'Tienda', 'Courier']}
          isLoading={isLoading}
          error={error}
          minHeight
        >
          {currentStores.map((store) => (
            <TableRow
              key={store.id}
              onClick={() => navigate(`${ROUTES.DISTRIBUTION_STORES.split(':')[0]}${store.id}`)}
              items={[
                store.storeId,
                store.name,
                <img src={store.distributionCourier.logoUrl} alt={store.distributionCourier.name} className="h-8" />
              ]}
            />
          ))}
        </Table>
      )}
      <PaginationFooter
        totalItems={searchResult}
        pageItems={currentStores}
        pageSize={pageSize}
        page={page}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </div>
  )
}

export default Stores
