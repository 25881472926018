import { React, useState, useCallback, useMemo, useContext, useEffect } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'
import secureLocalStorage from 'react-secure-storage'

import { AuthContext } from '../../contexts/Store'
import randomApi from '../../axiosConfig/randomApi'
import { compareCloseDates } from '../../helpers/manifests'
import { closeManifest, createManifest } from '../../helpers/request/manifests'
import { PACKAGE_TYPES } from '../../constants/sales'
import { MANIFEST_TABS } from '../../constants/manifest'
import { SIDEBAR_BUTTONS } from '../../constants/general'
import { MANIFESTS_URL, SHIPPINGS_URL } from '../../constants/urls'
import { isSubstring } from '../../utils/strings'
import { downloadFileUrl } from '../../utils/files'
import { searchTermsGenerator } from '../../utils/filters'
import NoAccess from '../../components/NoAccess'
import SearchBar from '../../components/shared/SearchBar'
import TabBar from '../../components/shared/TabBar/TabBar'
import SectionHeader from '../../components/shared/SectionHeader'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import ManifestTable from '../../components/Manifest/ManifestTable'
import ExportManifests from '../../components/Manifest/ExportManifests'
import ConfirmModal from '../../components/shared/ConfirmModal/ConfirmModal'
import ResponseModal from '../../components/shared/ResponseModal/ResponseModal'

const Manifest = () => {
  const autocompleteManifest = secureLocalStorage.getItem('autocompleteManifest')

  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [manifestsClose, setManifestsClose] = useState([])
  const [packageType = 0, setPackageType] = useQueryParam('packageType', NumberParam)
  const [selectedStatus, setSelectedStatus] = useState('create')
  const [selectedManifest, setSelectedManifest] = useState('')
  const [shippingManifestData, setShippingManifestData] = useState([])

  const [loadingClose, setLoadingClose] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [successOpen, setSuccessOpen] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [selectedShippingCount, setSelectedShippingCount] = useState('')

  const [closeManifestDynamicId, setCloseManifestDynamicId] = useState('')

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [errorShipping, setErrorShipping] = useState(false)
  const [isLoadingShipping, setIsLoadingShipping] = useState(false)

  const setManifestsInfo = useCallback((data) => {
    data.sort((manifestA, manifestB) => compareCloseDates(manifestA, manifestB))
    if (autocompleteManifest) {
      setShippingManifestData(data.filter(manifestItem => manifestItem.open === true))
    }
    setManifestsClose(data.filter(manifestItem => manifestItem.open === false))
    setSearchResult(data.filter(manifestItem => manifestItem.open === false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const params = useMemo(
    () => ({ params: { packageType: PACKAGE_TYPES[packageType] } }),
    [packageType]
  )

  const setShippingsInfo = useCallback((data) => {
    if (!autocompleteManifest) {
      setShippingManifestData(data)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getShippings = async () => {
    setIsLoadingShipping(true)
    try {
      const response = await randomApi().get(SHIPPINGS_URL, params)
      setShippingsInfo(response.data)
      setIsLoadingShipping(false)
    } catch {
      setErrorShipping(true)
      setIsLoadingShipping(false)
    }
  }

  const getManifests = async () => {
    setIsLoading(true)
    try {
      const response = await randomApi().get(MANIFESTS_URL, params)
      setManifestsInfo(response.data)
      setIsLoading(false)
    } catch {
      setError(true)
      setIsLoading(false)
    }
  }

  const searchBarFilter = (manifestsList, searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const filteredManifests = manifestsList.filter(
        (manifestItem) =>searchTerms.some((term) => (
          isSubstring(manifestItem.dynamicId, term) ||
          isSubstring(manifestItem.courier, term) ||
          isSubstring(manifestItem.channel, term)
        ))
      )
      setSearchResult(filteredManifests)
    } else {
      setSearchResult(manifestsClose)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    const manifestsList = manifestsClose
    searchBarFilter(manifestsList, searchTerm)
  }

  const updateManifestsList = async () => {
    setSearchBarInput('')
    if (autocompleteManifest) {
      getManifests()
    } else {
      getManifests()
      getShippings()
    }
  }

  const closeManifestSubmit = async () => {
    setLoadingClose(true)
    setSubmitError(false)
    try {
      const response = await closeManifest(selectedManifest.id, [])
      await updateManifestsList()
      if (!response.manifestPdf) setSubmitError(true)
      else {
        setCloseManifestDynamicId(selectedManifest.dynamicId)
        downloadFileUrl(response.manifestPdf)
      }
    } catch {
      setSubmitError(true)
    } finally {
      setConfirmOpen(false)
      setLoadingClose(false)
      setSuccessOpen(true)
    }
  }

  const createManifestSubmit = async () => {
    setLoadingClose(true)
    setSubmitError(false)
    const courier = (selectedManifest.courier || selectedManifest.channel)
    try {
      const response = await createManifest(courier, PACKAGE_TYPES[packageType], true, [])
      await updateManifestsList()
      if (!response.manifestPdf) setSubmitError(true)
      else {
        setCloseManifestDynamicId(response.dynamicId)
        downloadFileUrl(response.manifestPdf)
      }
    } catch {
      setSubmitError(true)
    } finally {
      setConfirmOpen(false)
      setLoadingClose(false)
      setSuccessOpen(true)
    }
  }

  useEffect(() => {
    if (autocompleteManifest) {
      getManifests()
    } else {
      getManifests()
      getShippings()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageType])

  if (!hasAccess('manifest')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  return (
    <div className="h-screen overflow-hidden bg-light-grey">
      <div className='flex flex-col'>
        <SectionHeader
          title={SIDEBAR_BUTTONS.manifest.text}
          icon={SIDEBAR_BUTTONS.manifest.inactiveIcon}
          rightChildren={
            <TabBar tabs={['Propios', 'Multicanal']} tab={packageType} setTab={setPackageType} />
          }
          searchBar
        />
        <ActionsBar
          statusFilters={MANIFEST_TABS}
          selectedFilters={selectedStatus}
          setSelectedFilters={setSelectedStatus}
          tabBar
          classNameRightChildren='ml-auto'
          rightChildren={
            <div className={`flex gap-1 ${ selectedStatus === 'create' ? 'hidden' : ''}`}>
              <ExportManifests
                manifests={selectedStatus === 'create' ? shippingManifestData : searchResult}
                packageType={packageType}
              />
              <SearchBar
                searchbarInput={searchBarInput}
                searchKeyword={searchHandler}
                setSearchbarInput={(search) => {
                  setSearchBarInput(search)
                }}
              />
            </div>
          }
        />
        <ManifestTable
          autocompleteManifest={autocompleteManifest}
          manifests={ selectedStatus === 'create' ? shippingManifestData : searchResult}
          selectedStatus={selectedStatus}
          isLoading={(autocompleteManifest ?
            isLoading : (isLoading || isLoadingShipping)) || loadingResources}
          updateManifestsList={updateManifestsList}
          packageType={packageType}
          errors={{error, errorShipping}}
          setSelectedManifest={setSelectedManifest}
          setConfirmOpen={setConfirmOpen}
          setSelectedShippingCount={setSelectedShippingCount}
          manifest={selectedManifest}
        />
      </div>
      <ConfirmModal
        isModalOpen={confirmOpen}
        loading={loadingClose}
        handleClose={() => setConfirmOpen(false)}
        handleConfirm={autocompleteManifest ? closeManifestSubmit : createManifestSubmit}
        twoButtons
      >
        <div className='flex flex-col justify-center items-center w-[200px] text-[#5E6570]'>
          <div className='font-medium text-base mb-4'>Crear Manifiesto</div>
          <div className='text-center'>
            <span className='font-normal text-sm'>¿Estas seguro/a de que deseas crear este manifiesto con </span>
            <span className='font-bold text-sm'>{selectedShippingCount} Pedidos</span>
          </div>
        </div>
      </ConfirmModal>
      <ResponseModal
        handleClose={ () => {
            updateManifestsList()
            setSuccessOpen(false)
          }
        }
        isModalOpen={successOpen}
        error={submitError}
        successMessage={`Manifiesto ${closeManifestDynamicId} cerrado con exitó`}
      />
    </div>
  )
}

export default Manifest
