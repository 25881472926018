import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { getFormattedDate } from '../../utils/dates'
import { Table, TableRow } from '../shared/Table/Table'
import ROUTES from '../../constants/routes'
import { STATUS_COLOR, STATUS_ICONS } from '../../constants/tickets'
import { notificationContext } from '../../contexts/NotificationContext'
import { countNewMessagesByTicketId } from '../../helpers/notifications'

const TicketsTable = ({
  tickets,
  handleSort,
  isLoading,
  error,
  selectedTickets,
  setSelectedTickets
}) => {
  const { ticketsInfo, setTicketsMessages } = useContext(notificationContext)
  const navigate = useNavigate()

  const checkAll = Object.keys(selectedTickets).length === Math.min(tickets.length)

  const handleCheckAll = () => {
    if (!checkAll) {
      setSelectedTickets(tickets.map((ticket) => {
        if (ticket.errorMessage) return null

        return ticket.id
      }).filter(Boolean))
    } else {
      setSelectedTickets([])
    }
  }

  useEffect(() => {
    if (tickets && ticketsInfo?.notifications) {
      setTicketsMessages(countNewMessagesByTicketId(ticketsInfo.notifications, {}, '', true))
    }
  }, [tickets, ticketsInfo?.notifications, setTicketsMessages])

  const isChecked = (ticket) => selectedTickets.includes(ticket.id)

  const changeSelected = (ticket) => {
    if (!isChecked(ticket)) {
      setSelectedTickets((prevSelected) => [...prevSelected, ticket.id])
    } else {
      setSelectedTickets(selectedTickets.filter((item) => item !== ticket.id))
    }
  }

  if (!isLoading && !error && tickets.length === 0) {
    return (
      <div className="mx-10 border text-center py-8 bg-white text-s">
        No hay tickets con este criterio
      </div>
    )
  }

  const sortValues = {
    'O. de venta': { key: 'orderId', type: 'string' },
    Estado: { key: 'status', type: 'string' },
    ID: { key: 'id', type: 'number' },
    Fecha: { key: 'createdAt', type: 'date' },
    Nombre: { key: 'customerName', type: 'string' },
    Motivo: { key: 'type', type: 'string' },
    Courier: { key: 'courier', type: 'string' }
  }

  const findNotification = (ticketId) =>
    ticketsInfo?.notifications?.find(
      (notification) =>
        notification.payload?.action?.url === `${ROUTES.TICKETS}/${ticketId}` && !notification.read
    )

  const navigateToTicket = async (ticketId) => {
    navigate(`${ROUTES.TICKETS}/${ticketId}`)
  }

  return (
    <Table
      maxHeight="max-h-[70vh]"
      sortValues={sortValues}
      sortData={handleSort}
      headers={[
        '',
        <input type="checkbox" checked={checkAll} onChange={handleCheckAll} />,
        'O. de venta',
        'Estado',
        'ID',
        'Fecha',
        'Nombre',
        'Motivo',
        'Courier'
      ]}
      isLoading={isLoading}
      error={error}
      minHeight
    >
      {tickets?.map((ticket) => (
        <TableRow
          key={ticket.id}
          items={[
            findNotification(ticket.id) ? (
              <div className="w-2 h-2 bg-red rounded-full" />
            ) : (
              <div className="w-2 h-2 bg-transparent" />
            ),
            <input type="checkbox" checked={isChecked(ticket)} onChange={() => changeSelected(ticket)} />,
            ticket.orderId,
            <div className={`flex gap-2 rounded w-max py-1 px-2 ${STATUS_COLOR[ticket.status]}`}>
              <img
                src={STATUS_ICONS[ticket.status]}
                alt={ticket.status}
                className="my-auto w-2.5 h-auto"
              />
              {ticket.status}
            </div>,
            ticket.id,
            getFormattedDate(ticket.createdAt),
            ticket.customerName,
            ticket.type.split('/')[1].trim(),
            ticket.courier || '-'
          ]}
          onClick={() => navigateToTicket(ticket.id)}
        />
      ))}
    </Table>
  )
}

export default TicketsTable
