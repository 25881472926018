/* eslint-disable react/prop-types */
import React from 'react'
import { sendBotMessage } from '../helpers/request/pinflagBot'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    if (!info.componentStack.includes('localhost') && !info.componentStack.includes('ngrok')) {
      const formattedStack = error.stack
      .split('\n')
      .map(line => line.replace(/\(http.*\/static\/js\/bundle\.js:/, ' (line '))
      .join('\n')

      const message = `Message: ${error.message}\n\n`
      + `React Component Stack: ${info.componentStack}\n\n`
      + `Formatted Stack Trace: ${formattedStack}`

      sendBotMessage(message, document.location.href)
    }
  }

  render() {
    const { hasError } = this.state
    const { fallback, children } = this.props

    if (hasError) {
      return fallback || <h2>Oops! Something went wrong.</h2>
    }

    return children
  }
}

export default ErrorBoundary
