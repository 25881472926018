import { React, useState } from 'react'

import useClickOutside from '../../../../hooks/useClickOutside'
import { SALES_WITH_ERROR_ICONS } from '../../../../constants/icons'
import { STATE_AND_CITY_NOT_FOUND_ERROR } from '../../../../constants/sales'
import Button from '../../../shared/Button'
import ModifyPackage from '../../SalesDetails/ModifyPackage/ModifyPackage'

const FixOrderWithErrorButton = ({status, deliveryType, saleEditableData, orderId, up, states}) => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()
  const [openModal, setOpenModal] = useState(false)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  const closeOnClick = true

  const hasError = (errorField) => {
    if (saleEditableData?.errorMessage === STATE_AND_CITY_NOT_FOUND_ERROR) return true

    return saleEditableData?.errorMessage === `${errorField} not found`
  }

  return (
    <div ref={popUpRef} className="relative">
      <Button onClick={handleClick} small>
        <div>
          <img src={SALES_WITH_ERROR_ICONS.addressError} alt="Modificar orden" className="w-3" />
        </div>
      </Button>
      {isOpen && (
          <div
            className={`absolute flex flex-col justify-center items-start ${up ? 'bottom-0' : 'top-0'} z-30
             bg-white rounded-lg shadow-xl text-xs py-2 px-3 w-max left-10`}
            onClick={closeOnClick ? handleClick : undefined}
            role="button"
            tabIndex="0"
            >
              <div className='text-base'>
                Dirección incorrecta
              </div>
              <div className='font-light pt-5'>
                Región
              </div>
              <div className={hasError('State') ? 'text-red' : ''}>
                {saleEditableData.state}
              </div>
              <div className='font-light pt-5'>
                Comuna
              </div>
              <div className={hasError('City') ? 'text-red' : ''}>
                {saleEditableData.cityName}
              </div>
              <div className='font-light pt-5'>
                Calle y número
              </div>
                {saleEditableData.address}
              <div className='pt-5'>
                <Button color="bg-normal-pinflag" onClick={() => setOpenModal(true)} small>
                Corregir
                </Button>
              </div>
          </div>
        )}
      <ModifyPackage
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        saleData={saleEditableData}
        clickAndCollect={deliveryType !== 'homeDelivery'}
        city={null}
        warehouseName={null}
        packageId={orderId}
        packageStatus={status}
        stateId={13}
        states={states}
        packageWithError
      />
    </div>
  )
}

export default FixOrderWithErrorButton
