import React, { useState, useEffect } from 'react'

import EditBox from '../../../Settings/Personalization/EditBox'
import { COLORS } from '../../../../constants/styles'
import ToggleSwitch from '../../../shared/ToggleSwitch/ToggleSwitch.style'
import OrderScheduleRowEdit from './ScheduleRowEdit'
import randomApi from '../../../../axiosConfig/randomApi'
import RawLoader from '../../../shared/Loader/RawLoader'
import LoadingError from '../../../shared/LoadingError'
import { DEFAULT_STORE_SCHEDULE } from '../../../../constants/stores'
import {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
  MONDAY_CODE,
  TUESDAY_CODE,
  WEDNESDAY_CODE,
  THURSDAY_CODE,
  FRIDAY_CODE,
  SATURDAY_CODE,
  SUNDAY_CODE
} from '../../../../constants/days'
import { findSchedule } from '../../../../helpers/warehouse'
import { WAREHOUSE_URL } from '../../../../constants/urls'
import Button from '../../../shared/Button'

const getSchedules = (schedules) => {
  if (!schedules || schedules.length === 0) {
    return {
      mondaySchedule: { ...DEFAULT_STORE_SCHEDULE, day: MONDAY },
      tuesdaySchedule: { ...DEFAULT_STORE_SCHEDULE, day: TUESDAY },
      wednesdaySchedule: { ...DEFAULT_STORE_SCHEDULE, day: WEDNESDAY },
      thursdaySchedule: { ...DEFAULT_STORE_SCHEDULE, day: THURSDAY },
      fridaySchedule: { ...DEFAULT_STORE_SCHEDULE, day: FRIDAY },
      saturdaySchedule: { ...DEFAULT_STORE_SCHEDULE, day: SATURDAY, open: false },
      sundaySchedule: { ...DEFAULT_STORE_SCHEDULE, day: SUNDAY, open: false }
    }
  }

  return {
    mondaySchedule: findSchedule(schedules, MONDAY),
    tuesdaySchedule: findSchedule(schedules, TUESDAY),
    wednesdaySchedule: findSchedule(schedules, WEDNESDAY),
    thursdaySchedule: findSchedule(schedules, THURSDAY),
    fridaySchedule: findSchedule(schedules, FRIDAY),
    saturdaySchedule: findSchedule(schedules, SATURDAY),
    sundaySchedule: findSchedule(schedules, SUNDAY)
  }
}

const ShipFromStore = ({ store, onUpdate }) => {
  const { active, schedules, packagePreparationTime, capacity } = store.ShippingWarehouse

  const [activeShipFromStore, setActiveShipFromStore] = useState(active)
  const [preparationTime, setPreparationTime] = useState(packagePreparationTime)
  const [maxCapacity, setMaxCapacity] = useState(capacity)

  const defaultSchedule = DEFAULT_STORE_SCHEDULE

  const {
    mondaySchedule,
    tuesdaySchedule,
    wednesdaySchedule,
    thursdaySchedule,
    fridaySchedule,
    saturdaySchedule,
    sundaySchedule
  } = getSchedules(schedules)

  const [monday, setMonday] = useState(mondaySchedule)
  const [tuesday, setTuesday] = useState(tuesdaySchedule)
  const [wednesday, setWednesday] = useState(wednesdaySchedule)
  const [thursday, setThursday] = useState(thursdaySchedule)
  const [friday, setFriday] = useState(fridaySchedule)
  const [saturday, setSaturday] = useState(saturdaySchedule)
  const [sunday, setSunday] = useState(sundaySchedule)

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [error, setError] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const orderedSchedules = [
    { day: MONDAY_CODE, schedule: mondaySchedule, setState: setMonday, state: monday },
    { day: TUESDAY_CODE, schedule: tuesdaySchedule, setState: setTuesday, state: tuesday },
    { day: WEDNESDAY_CODE, schedule: wednesdaySchedule, setState: setWednesday, state: wednesday },
    { day: THURSDAY_CODE, schedule: thursdaySchedule, setState: setThursday, state: thursday },
    { day: FRIDAY_CODE, schedule: fridaySchedule, setState: setFriday, state: friday },
    { day: SATURDAY_CODE, schedule: saturdaySchedule, setState: setSaturday, state: saturday },
    { day: SUNDAY_CODE, schedule: sundaySchedule, setState: setSunday, state: sunday }
  ]
  // eslint-disable-next-line
  const handleSubmit = async () => {
    const shippingWarehouseUpdateBody = {
      shipping: {
        packagePreparationTime: preparationTime,
        capacity: maxCapacity,
        active: activeShipFromStore
      }
    }

    const schedulesBody = {
      schedules: [monday, tuesday, wednesday, thursday, friday, saturday, sunday],
      type: 'shipping'
    }

    setIsLoadingSubmit(true)

    try {
      await randomApi().put(WAREHOUSE_URL(store.id), shippingWarehouseUpdateBody)
      await randomApi().put(`${WAREHOUSE_URL(store.id)}/schedules`, schedulesBody)
      setIsUpdated(false)

      await onUpdate()
    } catch {
      setError(true)
    } finally {
      setIsLoadingSubmit(false)
      setSubmitted(true)
    }
  }

  const handleCancel = () => {
    setActiveShipFromStore(active)
    setPreparationTime(packagePreparationTime)
    setMaxCapacity(capacity)

    orderedSchedules.forEach(({ schedule, setState }) => {
      setState(schedule || defaultSchedule)
    })

    setIsUpdated(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setSubmitted(false)
    }, 3000)
  }, [submitted])

  return (
    <>
      <div className="flex space-x-8">
         <div className="flex flex-col space-y-8">
            <EditBox
              title="Habilitado"
              description="Habilita la tienda para ser utilizada como punto de envío"
            >
              <ToggleSwitch
                size="small"
                inactiveColor={COLORS.LIGHT_GREY}
                check={activeShipFromStore}
                setCheck={() => {
                  setActiveShipFromStore(!activeShipFromStore)
                  setIsUpdated(true)
                }}
              />
            </EditBox>
            <EditBox
              title="Tiempo de preparación de paquete"
              description="Elige cuanto tiempo tendrán los operarios para procesar los pedidos. Sugerimos un mínimo de 15 min."
            >
              <div>
                <input
                  type="number"
                  className="flex border border-gray-300 rounded-md w-16 text-sm text-dark-grey justify-center items-center px-2"
                  value={preparationTime}
                  onChange={(e) => {
                    setPreparationTime(e.target.value)
                    setIsUpdated(true)
                  }}
                />
              </div>
            </EditBox>
          </div>
          <EditBox
            title="Capacidad"
            description="Configura la capacidad disponible de paquetes para el servicio"
          >
            <div>
              <input
                type="number"
                className="flex border border-gray-300 rounded-md w-16 text-sm text-dark-grey justify-center items-center px-2"
                value={maxCapacity}
                onChange={(e) => {
                  setMaxCapacity(e.target.value)
                  setIsUpdated(true)
                }}
              />
            </div>
          </EditBox>
          <EditBox
            title="Horario"
          >
            {orderedSchedules.map(({ day, setState, state }) => (
            <OrderScheduleRowEdit
              key={day}
              day={day}
              dayState={state}
              setDayState={setState}
              setIsUpdated={setIsUpdated}
            />
          ))}
          </EditBox>
        </div>
        {error && <LoadingError />}
      {isUpdated && !isLoadingSubmit && (
        <div className="flex gap-2 justify-center mt-2">
          <Button color="bg-normal-pinflag" onClick={handleSubmit} small>
            Guardar
          </Button>
          <Button color="bg-dark-grey" onClick={handleCancel} small>
            Cancelar
          </Button>
        </div>
      )}
      {isLoadingSubmit && <RawLoader />}
      {submitted && (
        <div className="flex justify-center mt-2">
          <div className="text-normal-pinflag">¡Guardado exitoso!</div>
        </div>
      )}
    </>
  )
}

export default ShipFromStore
