import React, { createContext, useCallback, useMemo, useState } from 'react'

import { NOTIFICATIONS_URL } from '../constants/urls'
import { countNewMessagesByTicketId } from '../helpers/notifications'
import useFetch from '../hooks/useFetch'
import { copyArray } from '../utils/arrays'

export const notificationContext = createContext()

const NotificationWrapper = ({ children }) => {
  const [salesInfo, setSalesInfo] = useState({})
  const [ticketsInfo, setTicketsInfo] = useState({})
  const [pointsInfo, setPointsInfo] = useState({})
  const [totalUnread, setTotalUnread] = useState(0)
  const [ticketsMessages, setTicketsMessages] = useState({})
  const [ticketsUnread, setTicketsUnread] = useState(0)

  const setNotifications = useCallback(async (notificationsData) => {
    setTotalUnread(notificationsData.totalUnread)
    if (notificationsData.Sales) {
      setSalesInfo(notificationsData.Sales)
    }
    if (notificationsData.Tickets) {
      setTicketsInfo(notificationsData.Tickets)
      setTicketsMessages(countNewMessagesByTicketId(notificationsData.Tickets.notifications, {}, '', true))
      setTicketsUnread(Object.keys(countNewMessagesByTicketId(notificationsData.Tickets.notifications, {}, '', true)).length)
    }
    if (notificationsData.Points) {
      setPointsInfo(notificationsData.Points)
    }
  }, [])

  useFetch(NOTIFICATIONS_URL, setNotifications)

  const addNotificationEvent = (event) => {
    if (event.data.category === 'Tickets') {
      const newNotification = {
        id: event.data.id,
        payload: event.data,
        read: false
      }
      setTicketsInfo({
        notifications: [...ticketsInfo.notifications, newNotification],
        unread: ticketsInfo.unread + 1
      })
      setTicketsUnread(Object.keys(countNewMessagesByTicketId([...ticketsInfo.notifications, newNotification], {}, '', true)).length)
    }

    setTotalUnread(totalUnread + 1)
  }

  const markNotificationsAsRead = useCallback(
    async (notificationIds, type) => {
      setTotalUnread(totalUnread - notificationIds.length)

      if (type !== 'Tickets') return

      const newTicketsNotifications = copyArray(ticketsInfo.notifications)

      for (const notificationId of notificationIds) {
        const index = newTicketsNotifications.findIndex(
          (notification) => notification.id === notificationId
          )

          newTicketsNotifications[index].read = true
        }

      setTicketsInfo({
        notifications: newTicketsNotifications,
        unread: ticketsInfo.unread - notificationIds.length
      })

      setTicketsUnread(Object.keys(countNewMessagesByTicketId(newTicketsNotifications, {}, '', true)).length)
    },
    [ticketsInfo, totalUnread]
  )

  navigator.serviceWorker.onmessage = (event) => {
    if (!event.data) return
    if (event.data.type === 'NOTIFICATION_UPDATE') addNotificationEvent(event)
  }

  const values = useMemo(
    () => ({
      salesInfo,
      ticketsInfo,
      pointsInfo,
      totalUnread,
      markNotificationsAsRead,
      setNotifications,
      ticketsMessages,
      setTicketsMessages,
      ticketsUnread,
      setTicketsUnread
    }),
    [salesInfo, ticketsInfo, pointsInfo, totalUnread, markNotificationsAsRead,
      setNotifications, ticketsMessages, ticketsUnread]
  )

  return <notificationContext.Provider value={values}>{children}</notificationContext.Provider>
}

export default NotificationWrapper
