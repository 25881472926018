import React, { useState, useEffect } from 'react'

import EditBox from '../../../Settings/Personalization/EditBox'
import ToggleSwitch from '../../../shared/ToggleSwitch/ToggleSwitch.style'
import { COLORS } from '../../../../constants/styles'
import StoreRackQRGenerator from './StoreRackQRGenerator'
import OrderScheduleRowEdit from './ScheduleRowEdit'
import { WAREHOUSE_URL } from '../../../../constants/urls'
import randomApi from '../../../../axiosConfig/randomApi'
import RawLoader from '../../../shared/Loader/RawLoader'
import LoadingError from '../../../shared/LoadingError'
import Button from '../../../shared/Button'
import { DEFAULT_STORE_SCHEDULE } from '../../../../constants/stores'
import {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
  MONDAY_CODE,
  TUESDAY_CODE,
  WEDNESDAY_CODE,
  THURSDAY_CODE,
  FRIDAY_CODE,
  SATURDAY_CODE,
  SUNDAY_CODE
} from '../../../../constants/days'
import { findSchedule } from '../../../../helpers/warehouse'

const StorePickup = ({ store, onUpdate }) => {
  const warehouseId = store.id

  const [maxAisles, setMaxAisles] = useState(store.PickupPointWarehouse.aisle)
  const [maxColumns, setMaxColumns] = useState(store.PickupPointWarehouse.column)
  const [maxHeight, setMaxHeight] = useState(store.PickupPointWarehouse.height)
  const [capacity, setCapacity] = useState(store.PickupPointWarehouse.capacity)
  const [activeStorePickup, setActiveStorePickup] = useState(store.PickupPointWarehouse.active)

  const mondaySchedule = findSchedule(store.schedules, MONDAY)
  const tuesdaySchedule = findSchedule(store.schedules, TUESDAY)
  const wednesdaySchedule = findSchedule(store.schedules, WEDNESDAY)
  const thursdaySchedule = findSchedule(store.schedules, THURSDAY)
  const fridaySchedule = findSchedule(store.schedules, FRIDAY)
  const saturdaySchedule = findSchedule(store.schedules, SATURDAY)
  const sundaySchedule = findSchedule(store.schedules, SUNDAY)

  const defaultSchedule = DEFAULT_STORE_SCHEDULE

  const [monday, setMonday] = useState(mondaySchedule || defaultSchedule)
  const [tuesday, setTuesday] = useState(tuesdaySchedule || defaultSchedule)
  const [wednesday, setWednesday] = useState(wednesdaySchedule || defaultSchedule)
  const [thursday, setThursday] = useState(thursdaySchedule || defaultSchedule)
  const [friday, setFriday] = useState(fridaySchedule || defaultSchedule)
  const [saturday, setSaturday] = useState(saturdaySchedule || defaultSchedule)
  const [sunday, setSunday] = useState(sundaySchedule || defaultSchedule)

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [error, setError] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const orderedSchedules = [
    { day: MONDAY_CODE, schedule: mondaySchedule, setState: setMonday, state: monday },
    { day: TUESDAY_CODE, schedule: tuesdaySchedule, setState: setTuesday, state: tuesday },
    { day: WEDNESDAY_CODE, schedule: wednesdaySchedule, setState: setWednesday, state: wednesday },
    { day: THURSDAY_CODE, schedule: thursdaySchedule, setState: setThursday, state: thursday },
    { day: FRIDAY_CODE, schedule: fridaySchedule, setState: setFriday, state: friday },
    { day: SATURDAY_CODE, schedule: saturdaySchedule, setState: setSaturday, state: saturday },
    { day: SUNDAY_CODE, schedule: sundaySchedule, setState: setSunday, state: sunday }
  ]

  // eslint-disable-next-line
  const handleSubmit = async () => {
    const pickupBody = {
      name: store.name,
      pickupPoint: {
        aisle: maxAisles,
        column: maxColumns,
        height: maxHeight,
        capacity,
        active: activeStorePickup
      }
    }

    const schedulesBody = {
      schedules: [monday, tuesday, wednesday, thursday, friday, saturday, sunday],
      type: 'pickupPoint'
    }

    setIsLoadingSubmit(true)

    try {
      await randomApi().put(`${WAREHOUSE_URL(warehouseId)}/schedules`, schedulesBody)
      await randomApi().put(WAREHOUSE_URL(warehouseId), pickupBody)
      setIsUpdated(false)

      await onUpdate()
    } catch {
      setError(true)
    } finally {
      setIsLoadingSubmit(false)
      setSubmitted(true)
    }
  }

  const handleCancel = () => {
    setMaxAisles(store.PickupPointWarehouse.aisle)
    setMaxColumns(store.PickupPointWarehouse.column)
    setMaxHeight(store.PickupPointWarehouse.height)
    setCapacity(store.PickupPointWarehouse.capacity)
    setActiveStorePickup(store.PickupPointWarehouse.active)

    orderedSchedules.forEach(({ schedule, setState }) => {
      setState(schedule)
    })

    setIsUpdated(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setSubmitted(false)
    }, 3000)
  }, [submitted])

  return (
    <>
      <div className="flex space-x-8">
        <div className="flex flex-col space-y-8">
          <EditBox
            title="Habilitado"
            description="Esta es la forma de entregar un pedido sin escanear el código de entrega del cliente"
          >
            <ToggleSwitch
              size="small"
              inactiveColor={COLORS.LIGHT_GREY}
              check={activeStorePickup}
              setCheck={() => {
                setActiveStorePickup(!activeStorePickup)
                setIsUpdated(true)
              }}
            />
          </EditBox>
          <EditBox
            title="Capacidad"
          >
            <div className="flex flex-col">
              <div className="flex flex-col mb-4">
                <div className="text text-ultra-dark-grey">Pasillos</div>
                <input
                  type="number"
                  value={maxAisles}
                  onChange={(e) => {
                    setMaxAisles(e.target.value)
                    setIsUpdated(true)
                  }}
                  className="flex border border-gray-300 rounded-md w-12 text-sm text-dark-grey justify-center items-center px-2"
                />
              </div>
              <div className="flex flex-col mb-4">
                <div className="text text-ultra-dark-grey">Columnas</div>
                <input
                  type="number"
                  value={maxColumns}
                  onChange={(e) => {
                    setMaxColumns(e.target.value)
                    setIsUpdated(true)
                  }}
                  className="flex border border-gray-300 rounded-md w-12 text-sm text-dark-grey justify-center items-center px-2"
                />
              </div>
              <div className="flex flex-col mb-4">
                <div className="text text-ultra-dark-grey">Pisos</div>
                <input
                  type="number"
                  value={maxHeight}
                  onChange={(e) => {
                    setMaxHeight(e.target.value)
                    setIsUpdated(true)
                  }}
                  className="flex border border-gray-300 rounded-md w-12 text-sm text-dark-grey justify-center items-center px-2"
                />
              </div>
              <div className="flex flex-col mb-4">
                <div className="text text-ultra-dark-grey">Capacidad máxima</div>
                <input
                  type="number"
                  value={capacity}
                  onChange={(e) => {
                    setCapacity(e.target.value)
                    setIsUpdated(true)
                  }}
                  className="flex border border-gray-300 rounded-md w-20 text-sm text-dark-grey justify-center items-center px-2"
                />
              </div>
            </div>
          </EditBox>
        </div>
        <EditBox
          title="Código QR Rack"
        >
          <StoreRackQRGenerator
            maxAisle={maxAisles}
            maxDepth={maxColumns}
            maxHeight={maxHeight}
          />
        </EditBox>
        <EditBox
          title="Horario"
        >
          {orderedSchedules.map(({ day, setState, state }) => (
            <OrderScheduleRowEdit
              key={day}
              day={day}
              dayState={state}
              setDayState={setState}
              setIsUpdated={setIsUpdated}
            />
          ))}
        </EditBox>
      </div>
      {error && <LoadingError />}
      {isUpdated && !isLoadingSubmit && (
        <div className="flex gap-2 justify-center mt-2">
          <Button color="bg-normal-pinflag" onClick={handleSubmit} small>
            Guardar
          </Button>
          <Button color="bg-dark-grey" onClick={handleCancel} small>
            Cancelar
          </Button>
        </div>
      )}
      {isLoadingSubmit && <RawLoader />}
      {submitted && (
        <div className="flex justify-center mt-2">
          <div className="text-normal-pinflag">¡Guardado exitoso!</div>
        </div>
      )}
    </>
  )
}

export default StorePickup
